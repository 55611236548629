import React, { useState, createContext } from 'react'
import { getPropertyFromLocalStorage } from '../utils/storage'

// Create Context Object
export const LanguageContext = createContext()

// Create a provider for components to consume and subscribe to changes
export const LanguageContextProvider = (props) => {
  const initialLanguage = getPropertyFromLocalStorage('language')
  const [language, setLanguage] = useState(initialLanguage)

  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      {props.children}
    </LanguageContext.Provider>
  )
}
