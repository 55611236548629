import React from 'react'
import renderIf from 'render-if'
import locale from '../utils/locale'
import { getPropertyFromLocalStorage } from '../utils/storage'
import './style.css'

export default function LotStatus(props) {
  const countryCode = getPropertyFromLocalStorage('countryCode') || 'deu'

  return (
    <div className="section">
      {renderIf(props.lotDetails.mbr_sale === 'CLOSED')(
        <div>
          <h2 className="successText">{locale('readyLabel')}</h2>
          <p>{locale('readyText')}</p>
          <p className="gatePassText">
            <span>{`${locale('gatePassPin')}: `}</span>
            <span className="pin">{props.lotDetails.gate_pin_cd}</span>
          </p>
        </div>
      )}
      {renderIf(props.lotDetails.mbr_sale !== 'CLOSED')(
        <div>
          <h2 className="pendingText">{locale('paymentPending')}</h2>
          <p>
            {countryCode === 'deu'
              ? locale('germanyPendingText')
              : locale('spainPendingText')}
          </p>
          <p className="gatePassText">
            {`${locale('gatePassPin')}: `}
            <span className="pin">{props.lotDetails.gate_pin_cd}</span>
          </p>
        </div>
      )}
    </div>
  )
}
