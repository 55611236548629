import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { LanguageContextProvider } from './context/LanguageContext'
import Main from './pages/Main'
import InvalidPage from './pages/InvalidPage'
import { setPropertyInLocalStorage } from './utils/storage'

const Router = () => {
  const host = window.location.host.split('.')
  const languageCode = host[host.length - 1]
  setPropertyInLocalStorage(
    'language',
    languageCode === 'de' || languageCode === 'es' ? languageCode : 'en'
  )
  setPropertyInLocalStorage(
    'countryCode',
    languageCode === 'de' ? 'deu' : 'esp'
  )
  return (
    <BrowserRouter>
      <LanguageContextProvider>
        <Switch>
          <Route exact path="/:id" component={Main} />
          <Route path="*" component={InvalidPage} />
        </Switch>
      </LanguageContextProvider>
    </BrowserRouter>
  )
}

export default Router
