import React from 'react'
import GatePassHeader from '../components/GatePassHeader'
import locale from '../utils/locale'
import { Container } from 'semantic-ui-react'
import './style.css'

export default function InvalidPage(props) {
  return (
    <>
      <GatePassHeader countryCode={props.match.params.countryCode} />
      <Container className="ui center aligned">
        <div className="errorSection">
          <h2>{locale('Invalid Link')}</h2>
        </div>
      </Container>
    </>
  )
}
