import { propOr, assoc } from 'ramda'

export function getPropertyFromLocalStorage(key) {
  const gatePassPin = localStorage.getItem('gate-pass-pin')
  const obj = gatePassPin ? JSON.parse(gatePassPin) : {}
  return propOr('', key, obj)
}

export function setPropertyInLocalStorage(key, value) {
  const gatePassPin = localStorage.getItem('gate-pass-pin')
  const obj = gatePassPin ? JSON.parse(gatePassPin) : {}
  localStorage.setItem('gate-pass-pin', JSON.stringify(assoc(key, value)(obj)))
}
