// eslint-disable-next-line
export default {
  gatePassPin: 'PIN de autorización de retirada',
  readyLabel: '¡Estamos listos para ti!',
  readyText:
    'Cuando llegue a Copart, siga las señales y estacione su camión en el área de carga designada y un operador de carga de Copart lo ayudará.',
  paymentPending: 'El pago está pendiente',
  spainPendingText:
    'Todos los cargos pendientes deben pagarse antes de la recogida del vehículo bien mediante transferencia bancaria o tarjeta de crédito (esta última sólo en las instalaciones de Madrid)',
  lotDetails: 'Detalles del lote',
  lotNumber: 'Lote Nº',
  description: 'Descripción',
  paymentStatus: 'Situación de pagos',
  pickupAddress: 'Dirección de Recogida',
  copartLocation: 'Ubicación de Copart',
  vin: 'Nº Bastidor',
  licensePlate: 'Nº Matrícula',
  grossWeight: 'Peso Bruto',
  auctionDate: 'Fecha de la subasta:',
  itemNo: 'Artículo n.°',
  rowLocation: 'Localización de la Fila',
  towable: 'Remolcable',
  damageType: 'Tipos de daño',
  validThroughDate: 'Válido hasta la Fecha',
  chargesDue: 'Cargos pendientes',
  pending: 'Pendiente',
  complete: 'Complete',
  'Invalid Link': 'Enlace no válido',
  None: 'Ninguno',
  Burn: 'Calcinado',
  Collision: 'Colisión',
  Hail: 'Granizo',
  Wind: 'Viento',
  Catastrophe: 'Catástrofe',
  'Other Comprehensive': 'Otros Integral',
  'Property Damage': 'Daños a la Propiedad',
  Vandalism: 'Vandalismo',
  Theft: 'Robo',
  'Uninsured Motorists': 'Motoristas sin seguro',
  'Flood/Water': 'Inundado',
  Yes: 'Sí',
  No: 'No',
  Unknown: 'Desconocido',
  keys: 'Llaves',
  Exempt: 'Exento',
  English: 'Inglés',
  Spanish: 'Español',
  Deutsch: 'Deutsch'
}
