import React from 'react'
import { isEmpty } from 'ramda'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

export default function ImageSlider(props) {
  const renderImages = (images = []) =>
    images.map((item) => (
      <div key={item.sequence}>
        <img alt={`${item.img_type_cd}_${item.sequence}`} src={item.original} />
      </div>
    ))

  return (
    <Carousel autoPlay showThumbs={false} showStatus={false}>
      {!isEmpty(props.images) ? renderImages(props.images) : null}
    </Carousel>
  )
}
