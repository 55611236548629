// eslint-disable-next-line
export default {
  gatePassPin: 'Gate Pass PIN',
  readyLabel: "We're Ready For You!",
  readyText:
    'When you arrive at Copart, please follow signs and park your truck in the designated loading area and a Copart loader operator will assist you.',
  paymentPending: 'Payment is Pending',
  germanyPendingText:
    'All outstanding charges must be paid before vehicle pickup and can be paid by bank transfer or by credit card or cash at all Copart facilities.',
  spainPendingText:
    'All outstanding charges must be paid before vehicle pickup and can be paid by bank transfer or by credit card (at the Madrid facility only)',
  lotDetails: 'Lot Details',
  lotNumber: 'Lot Number',
  description: 'Description',
  paymentStatus: 'Payment Status',
  pickupAddress: 'Pickup Address',
  copartLocation: 'Copart Location',
  vin: 'VIN',
  licensePlate: 'License Plate',
  grossWeight: 'Gross Weight',
  auctionDate: 'Auction Date',
  itemNo: 'Item No',
  rowLocation: 'Row Location',
  towable: 'Towable',
  damageType: 'Damage Type',
  validThroughDate: 'Valid Through Date',
  chargesDue: 'Charges Due',
  pending: 'PENDING',
  complete: 'COMPLETE',
  'Invalid Link': 'Invalid Link',
  None: 'None',
  Burn: 'Burn',
  Collision: 'Collision',
  Hail: 'Hail',
  Wind: 'Wind',
  Catastrophe: 'Catastrophe',
  'Other Comprehensive': 'Other Comprehensive',
  'Property Damage': 'Property Damage',
  Vandalism: 'Vandalism',
  Theft: 'Theft',
  'Uninsured Motorists': 'Uninsured Motorists',
  'Flood/Water': 'Flood/Water',
  Yes: 'Yes',
  No: 'No',
  Unknown: 'Unknown',
  keys: 'Keys',
  Exempt: 'Exempt',
  English: 'English',
  Spanish: 'Spanish',
  Deutsch: 'Deutsch'
}
