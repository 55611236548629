import React, { useEffect, useState } from 'react'
import { Accordion, Menu, Label, Icon } from 'semantic-ui-react'
import { propOr, pathOr } from 'ramda'
import locale from '../utils/locale'
import './style.css'

export default function PickupDetails({ addressDetails }) {
  const [activeIndex, setActiveIndex] = useState(true)
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [fullAddress, setFullAddress] = useState('')

  function getAddressDetails(pickupAddress) {
    setState(pickupAddress.state ? pickupAddress.state : '')
    setCity(pickupAddress.city)
    setFullAddress(`${pickupAddress.addr_line_1} , ${
      pickupAddress.addr_line_2 ? pickupAddress.addr_line_2 + ', ' : ''
    }
    ${city} , ${state} , ${pickupAddress.postal_cd}`)
  }

  const formatPhoneNo = (phoneNo = '') => {
    const country = pathOr('', ['address', 'cntry_cd'], addressDetails)
    return phoneNo
      ? country === 'DEU'
        ? `+${phoneNo.substr(0, 2)} ${phoneNo.substr(2, 4)}
        ${phoneNo.substr(6)}`
        : `+${phoneNo.substr(0, 2)} ${phoneNo.substr(2, 3)} 
        ${phoneNo.substr(5, 2)} ${phoneNo.substr(7, 2)} ${phoneNo.substr(9)}`
      : '-'
  }

  useEffect(() => {
    const address = propOr({}, 'address', addressDetails)
    if (address) {
      getAddressDetails(address)
    }
  })

  const PickupAddress = (
    <div className="ui left aligned container addressDetails">
      <h5 className="pin">{locale('copartLocation')}</h5>
      <h5 className="facilityDetails">
        <Icon name="map marker alternate"></Icon>
        <a
          target="_blank"
          href={`http://maps.google.com/maps?z=10&t=m&q=loc:${addressDetails.lat}+${addressDetails.lng}`}
        >
          <span>{`${addressDetails.facility_nm}, `}</span>
          <span>{fullAddress}</span>
        </a>
      </h5>
      <h5 className="facilityDetails">
        <Icon name="phone"></Icon>
        <a href={`tel:${addressDetails.phone}`}>
          <span>{formatPhoneNo(addressDetails.phone)}</span>
        </a>
      </h5>
    </div>
  )

  return (
    <Accordion fluid styled className="section">
      <Menu.Item>
        <Accordion.Title
          active={activeIndex}
          onClick={() => setActiveIndex(!activeIndex)}
          index={0}
          className="accordionTitle accordionHeader"
        >
          <div>
            <span className="textAlignRight">
              {activeIndex ? (
                <Icon name="caret up" />
              ) : (
                <Icon name="caret down" />
              )}
            </span>
            <span className="textAlignLeft">{locale('pickupAddress')}</span>
          </div>
        </Accordion.Title>
        <Accordion.Content active={activeIndex} content={PickupAddress} />
      </Menu.Item>
    </Accordion>
  )
}
