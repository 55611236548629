import React, { useEffect, useState, useContext } from 'react'
import { Container, Dimmer, Loader } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { pathOr } from 'ramda'
import renderIf from 'render-if'
import uuid from 'uuid'
import GatePassHeader from '../components/GatePassHeader'
import LotStatus from '../components/LotStatus'
import LotDetails from '../components/LotDetails'
import PickupDetails from '../components/PickupDetails'
import { LanguageContextProvider } from '../context/LanguageContext'
import locale from '../utils/locale'
import { getPropertyFromLocalStorage } from '../utils/storage'
import './style.css'

export default function Main() {
  const [pickupAddress, setPickupAddress] = useState({})
  const [lotDetails, setLotDetails] = useState({})
  const [lotImages, setLotImages] = useState([])
  const [isLoading, setLoaderFlag] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const { id } = useParams()

  const fetchRequestData = async () => {
    try {
      const countryCode = getPropertyFromLocalStorage('countryCode')
      const response = await axios.get(
        `/cobalt/${countryCode}/api/v1/gate_pins/id/${id}`,
        {
          headers: {
            languagecode: getPropertyFromLocalStorage('language') || 'en',
            correlationid: `gatepin-uuid-${uuid()}`
          }
        }
      )
      if (response.status === 200) {
        const address = pathOr({}, ['data', 'facility'], response)
        const lotDetails = pathOr({}, ['data', 'lot'], response)
        const lotImages = pathOr({}, ['data', 'lot_images'], response)
        setPickupAddress(address)
        setLotDetails(lotDetails)
        setLotImages(lotImages)
      }
      setLoaderFlag(false)
    } catch (error) {
      const errorMsg = pathOr(
        'Invalid Link',
        ['data', 'error', 'message'],
        error.response
      )
      setErrorMessage(errorMsg)
      setLoaderFlag(false)
    }
  }

  useEffect(() => {
    fetchRequestData()
  }, [])

  return (
    <LanguageContextProvider>
      {renderIf(isLoading)(
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      )}
      {renderIf(!isLoading)(
        <>
          <GatePassHeader onLanguageChange={fetchRequestData} />
          <Container className="ui center aligned">
            {errorMessage ? (
              <div className="errorSection">
                <h2>{locale(errorMessage)}</h2>
              </div>
            ) : (
              <>
                <LotStatus lotDetails={lotDetails} />
                <PickupDetails addressDetails={pickupAddress} />
                <LotDetails lotDetails={lotDetails} lotImages={lotImages} />
              </>
            )}
          </Container>
        </>
      )}
    </LanguageContextProvider>
  )
}
