import React, { useState } from 'react'
import renderIf from 'render-if'
import { Accordion, Menu, Table, Icon } from 'semantic-ui-react'
import { isEmpty, pathOr, propOr } from 'ramda'
import moment from 'moment'
import locale from '../utils/locale'
import ImageSlider from './ImageSlider'
import './style.css'

export default function LotDetails({ lotDetails = {}, lotImages = [] }) {
  const [activeIndex, setActiveIndex] = useState(true)

  const lotNumber = propOr('', 'lot_num', lotDetails)
  const makeName = pathOr('', ['make', 'make_nm'], lotDetails)
  const modelName = pathOr('', ['model', 'model_nm'], lotDetails)
  const modelYear = propOr('', 'model_year', lotDetails)
  const vehicleColor = pathOr('', ['color', 'clr_desc'], lotDetails)
  const vinNumber = propOr('', 'vin_srl', lotDetails)
  const licensePlate = propOr('-', 'lic_plate', lotDetails)
  const grossWeight = propOr('', 'gross_veh_wgt', lotDetails)
  const weightUnit = propOr('', 'wgt_uom_cd', lotDetails)
  const auctionDate = propOr('', 'auction_date', lotDetails)
  const itemNumber = propOr('-', 'item_num', lotDetails)
  const yardRow = propOr({}, 'yard_row', lotDetails)
  const keys = propOr({}, 'haskey_value', lotDetails)
  const towable = propOr({}, 'towable_value', lotDetails)
  const lossType = propOr({}, 'loss_type', lotDetails)
  const validThroughDate = propOr('', 'qr_cd_exp_dt', lotDetails)
  const paymentStatus = propOr('', 'mbr_sale', lotDetails)
  const chargesDue = propOr('', 'pending_member_charges', lotDetails)

  const formatCurrency = (value) =>
    new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    }).format(value)

  const lotInfo = renderIf(lotDetails)(
    <Table unstackable basic="very">
      <Table.Body>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('lotNumber')}
          </Table.Cell>
          <Table.Cell>{lotNumber}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('description')}
          </Table.Cell>
          <Table.Cell>
            {`${makeName} ${modelName} ${modelYear} ${vehicleColor}`}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('vin')}
          </Table.Cell>
          <Table.Cell>{vinNumber}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('licensePlate')}
          </Table.Cell>
          <Table.Cell>{licensePlate}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('grossWeight')}
          </Table.Cell>
          <Table.Cell>
            {grossWeight ? `${grossWeight} ${weightUnit}` : '-'}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('auctionDate')}
          </Table.Cell>
          <Table.Cell>
            {auctionDate ? moment(auctionDate).format('DD-MM-YYYY') : '-'}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('itemNo')}
          </Table.Cell>
          <Table.Cell>{itemNumber}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('rowLocation')}
          </Table.Cell>
          <Table.Cell>
            {!isEmpty(yardRow)
              ? `${yardRow.yard_row_cd} : ${yardRow.yard_row_nm}`
              : '-'}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('keys')}
          </Table.Cell>
          <Table.Cell>
            {!isEmpty(keys) ? locale(keys.haskey_desc) : '-'}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('towable')}
          </Table.Cell>
          <Table.Cell>
            {!isEmpty(towable) ? locale(towable.towbl_desc) : '-'}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('damageType')}
          </Table.Cell>
          <Table.Cell>
            {lossType.loss_type_desc ? locale(lossType.loss_type_desc) : '-'}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('validThroughDate')}
          </Table.Cell>
          <Table.Cell>
            {validThroughDate
              ? moment(validThroughDate).format('DD-MM-YYYY')
              : '-'}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('paymentStatus')}
          </Table.Cell>
          <Table.Cell
            className={
              paymentStatus === 'CLOSED' ? 'successText' : 'pendingText'
            }
          >
            {paymentStatus === 'CLOSED'
              ? locale('complete')
              : ['OPEN', 'BILLED'].includes(paymentStatus)
              ? locale('pending')
              : paymentStatus}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={5} className="pin">
            {locale('chargesDue')}
          </Table.Cell>
          <Table.Cell>{formatCurrency(chargesDue)}</Table.Cell>
        </Table.Row>
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="2">
            <ImageSlider images={lotImages} />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  )

  return (
    <Accordion fluid styled className="section">
      <Menu.Item>
        <Accordion.Title
          active={activeIndex}
          onClick={() => setActiveIndex(!activeIndex)}
          className="accordionTitle accordionHeader"
        >
          <div className="accordionHeader">
            <span className="textAlignRight">
              {activeIndex ? (
                <Icon name="caret up" />
              ) : (
                <Icon name="caret down" />
              )}
            </span>
            <span className="textAlignLeft">{locale('lotDetails')}</span>
          </div>
        </Accordion.Title>
        <Accordion.Content active={activeIndex} content={lotInfo} />
      </Menu.Item>
    </Accordion>
  )
}
