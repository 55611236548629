import React, { useContext, useEffect, useState } from 'react'
import { Segment, Header, Dropdown } from 'semantic-ui-react'
import { Flag } from './Flag'
import {
  setPropertyInLocalStorage,
  getPropertyFromLocalStorage
} from '../utils/storage'
import locale from '../utils/locale'
import { LanguageContext } from '../context/LanguageContext'

const countryStyles = (countryCode) => {
  if (!countryCode) return { height: '24px' }
  return ['deu', 'esp'].includes(countryCode)
    ? { height: '30px' }
    : { height: '32px' }
}

export default function GatePassHeader(props) {
  const [language, setLanguage] = useContext(LanguageContext)
  const [languageText, setLanguageText] = useState('English')
  const countryCode = getPropertyFromLocalStorage('countryCode') || 'deu'
  const languageOptions = [
    {
      key: 'en',
      text: locale('English'),
      value: 'en'
    }
  ]

  function getLanguageOptions() {
    if (countryCode === 'deu') {
      languageOptions.unshift({
        key: 'de',
        text: locale('Deutsch'),
        value: 'de'
      })
    } else if (countryCode === 'esp') {
      languageOptions.unshift({
        key: 'es',
        text: locale('Spanish'),
        value: 'es'
      })
    }
    return languageOptions
  }

  useEffect(() => {
    switch (language) {
      case 'de':
        setLanguageText('Deutsch')
        break
      case 'es':
        setLanguageText('Spanish')
        break
      default:
        setLanguageText('English')
    }
  }, [language])

  return (
    <Segment clearing className="accordionTitle header">
      <Header as="h5" floated="left" className="headerText">
        {locale('gatePassPin')}
      </Header>
      <Header as="h5" floated="right" className="">
        <Dropdown
          pointing="right"
          className="CountryDropDown"
          onChange={(e, { value }) => {
            setLanguage(value)
            setPropertyInLocalStorage('language', value)
            props.onLanguageChange()
          }}
          icon={
            <Flag
              countryCode={countryCode}
              style={countryStyles(countryCode)}
            />
          }
          options={getLanguageOptions()}
          text={locale(languageText)}
        />
      </Header>
    </Segment>
  )
}
