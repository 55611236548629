import React from 'react'
import { propOr } from 'ramda'
import { countryMapper } from '../constants'

const defaultCountry = 'deu'

export function Flag(props) {
  const countryCode = propOr(defaultCountry, 'countryCode', props).toLowerCase()
  const isValidCountry = Object.keys(countryMapper).includes(countryCode)
  return (
    <>
      <img
        src={`${process.env.PUBLIC_URL}/flag_icons/flag_${
          isValidCountry ? countryCode : defaultCountry
        }.png`}
        style={props.style}
        alt={countryCode}
      />
      {props.text}
    </>
  )
}
