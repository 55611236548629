// eslint-disable-next-line
export default {
  gatePassPin: 'Tor-Pass-PIN',
  readyLabel: 'Wir sind bereit für Sie!',
  readyText:
    'Wenn Sie bei Copart ankommen, folgen Sie bitte der Beschilderung, parken Sie Ihren LKW in der ausgewiesenen Ladezone und ein Copart-Mitarbeiter wird Ihnen weiterhelfen.',
  paymentPending: 'Ausstehende Zahlung',
  germanyPendingText:
    'Alle ausstehenden Gebühren müssen vor der Fahrzeugabholung beglichen werden und können per Banküberweisung, per Kreditkarte oder in bar an allen Copart-Standorten bezahlt werden.',
  lotDetails: 'Lotdetails',
  lotNumber: 'Gruppennummer',
  description: 'Beschreibung',
  paymentStatus: 'Status der Zahlung',
  pickupAddress: 'Abholadresse',
  copartLocation: 'Copart Standort',
  vin: 'FIN',
  licensePlate: 'Kennzeichen',
  grossWeight: 'Bruttogewicht',
  auctionDate: 'Auktionsdatum',
  itemNo: 'Art.-Nr.',
  rowLocation: 'Position der Reihe auf dem Platz',
  towable: 'Rollfähig',
  damageType: 'Beschädigungsart',
  validThroughDate: 'Gültigkeitsdatum',
  chargesDue: 'Fällige Gebühren',
  pending: 'Ausstehend',
  complete: 'vollständig',
  'Invalid Link': 'Ungültiger Link',
  None: 'Keiner',
  Burn: 'Brandschaden',
  Collision: 'Zusammenprall',
  Hail: 'Hagel',
  Wind: 'Sturm',
  Catastrophe: 'Katastrophe',
  'Other Comprehensive': 'andere Schadenart',
  'Property Damage': 'Sachschäden',
  Vandalism: 'Vandalismus',
  Theft: 'Diebstahl',
  'Uninsured Motorists': 'nicht versichert',
  'Flood/Water': 'Wasserschaden',
  Yes: 'Ja',
  No: 'Nein',
  Unknown: 'Nicht bekannt',
  keys: 'Schlüsselhandling',
  Exempt: 'befreit',
  English: 'English',
  Spanish: 'Spanisch',
  Deutsch: 'Deutsch'
}
